<template>
    <div id="contents" class="contents video_add_page">
        <div class="content_head">
            <!-- s: 페이지 타이틀 -->
            <h2 class="page_ttl" v-if="pageMode !== 'modify'">영상 등록</h2>
            <h2 class="page_ttl" v-if="pageMode === 'modify'">영상 수정</h2>
            <!-- e: 페이지 타이틀 -->
        </div>

        <!-- s: content_body -->
        <div class="content_body">
            <!-- s: input_sec -->
            <div class="board_sec">
                <div class="tbl_wrap list form">
                    <table class="tbl">
                        <caption></caption>
                        <colgroup>
                            <col width="200"/>
                            <col/>
                            <col width="200"/>
                            <col/>
                        </colgroup>
                        <tbody>
                        <!-- Dev 수정모드일 경우에만 등록일시/수정일시 노출 -->
                        <tr v-if="pageMode === 'modify'">
                            <th>등록 일시</th>
                            <td>
                                <p>2021.07.05 12:33:56</p>
                            </td>
                            <th>수정 일시</th>
                            <td>
                                <p>2021.07.05 12:33:56</p>
                            </td>
                        </tr>
                        <tr>
                            <th class="must_txt">영상 파일</th>
                            <td colspan="3">
                                <div class="bg_box">
                                    <ul>
                                        <li>
                                            <span>영상 파일형식 MP4 </span>
                                            <span>용량 30MB 이하</span>
                                            <span>해상도 1920*1080</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="upload_area clearFix">
                                    <!-- Dev: 파일 첨부 후 is_error 클래스 삭제, video 클래스 추가 -->
                                    <div class="upload_box flL is_error" :class="videoFile ? 'video' : ''">
                                        <!-- Dev s: 등록된 파일 있을 경우 -->
                                        <div v-if="videoFile" class="upload_btn has_file" role="button">
                                            <figure v-if="isImage(videoFile)" class="uploaded_img">
                                                <img :src="getFile(videoFile)" alt="">
                                            </figure>
                                            <input type="file"
                                                   accept="video/mp4"
                                            >
                                            <div class="btn_area">
                                                <!-- Dev : 다운로드 버튼은 수정 페이지에서만 보이게 해주세요 -->
                                                <button v-if="pageMode === 'modify'" type="button"
                                                        class="btn w_s h_s basic">
                                                    다운로드
                                                </button>
                                                <button type="button" class="btn w_s h_s basic">
                                                    삭제
                                                </button>
                                            </div>
                                            <p class="filename" v-if="typeof file === 'string'">{{ fileNameFromUrl(vmd.files[index]) }}</p>
                                            <p class="filename" v-else>{{ videoFile.name }}</p>
                                        </div>
                                        <!-- Dev e: 등록된 파일 있을 경우 -->

                                        <!-- Dev s: 등록된 파일 없을 경우 -->
                                        <div v-if="!videoFile" class="upload_btn" role="button">
                                            <div class="txt_area">
                                                <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                                            </div>
                                            <div class="btn_area">
                                                <button type="button" class="btn basic w_s h_s">파일추가</button>
                                            </div>
                                            <input type="file" accept=".mp4" @change="onFileChange($event)">
                                        </div>
                                        <small v-if="!videoFile" class="error_txt">파일을 선택 해 주세요.</small>
                                        <!-- Dev e: 등록된 파일 없을 경우 -->
                                    </div>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
            <!-- e: board_sec -->
        </div>
        <!-- e: content_body -->

        <!-- s: content_foot -->
        <div class="content_foot">
            <div class="btn_group center">
                <button class="btn basic w_l h_m" @click="redirect('/mainVideo')">취소</button>
                <button v-if="pageMode === 'modify'" class="btn basic w_l h_m" @click="removeVideo()">삭제</button>
                <button v-if="pageMode === 'modify'" class="btn strong w_l h_m" @click="modifyVideo()">수정</button>
                <button v-else class="btn strong w_l h_m" @click="uploadVideo">저장</button>
            </div>
        </div>
        <!-- e: content_foot -->
    </div>

    <!-- s: 삭제 확인 Dialog -->
    <Dialog :isOpen="deleteDialogState" :yes="'삭제'" :cancel="'취소'" v-on:cancelDialog="deleteDialogState = false"
            v-on:yesDialog="confirmRemove()">
        <template #body>
            <h3>삭제 확인</h3>
            <p>영상를 삭제하시겠습니까? <br/>영상을 삭제한 이후 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
    <!-- e: 삭제 확인 Dialog -->

    <!-- s: 수정 확인 Dialog -->
    <Dialog :isOpen="modifyDialogState" :yes="'수정'" :cancel="'취소'" v-on:cancelDialog="modifyDialogState = false"
            v-on:yesDialog="confirmModify()">
        <template #body>
            <h3>수정 확인</h3>
            <p>영상를 수정하시겠습니까? <br/>영상을 수정한 이후 "발행하기"를 진행해야 체험존에 반영됩니다.</p>
        </template>
    </Dialog>
    <!-- e: 수정 확인 Dialog -->
</template>

<script>
import Dialog from "@/components/dialog";
import {fileNameFromUrl} from "@/plugins/network";


export default {
    name: "index",
    layout: {
        name: 'admin',
    },
    components: {
        Dialog
    },
    data() {
        return {
            pageMode: '',
            deleteDialogState: false,
            modifyDialogState: false,
            videoFile: null,
        }
    },
    methods: {
        fileNameFromUrl: fileNameFromUrl,
        onFileChange(e){
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length)
                return;
            let file = files[0];

            const isVideo = this.isVideo(file);
            const maxVideoSize = 80 * 1024 * 1024;
            if (isVideo) {
                if (maxVideoSize <= file.size) {
                    this.toastDanger('동영상 용량은 80MB 이하로 등록해주세요.');
                    return;
                }
            }else{
                this.toastDanger('MP4 동영상 파일만 가능합니다.');
                return;
            }

            this.videoFile = file;
        },
        uploadVideo(){

        },
        removeVideo() {
            this.deleteDialogState = true;
        },
        modifyVideo() {
            this.modifyDialogState = true;
        },
        confirmRemove() {
            this.deleteDialogState = false;
            this.redirect('/mainVideo');
            // this.toastSuccess('영상이 삭제되었습니다.'); 리스트 페이지로 이동 후 토스트 팝업 호출
        },
        confirmModify() {
            this.modifyDialogState = false;
            this.redirect('/mainVideo');
            // this.toastSuccess('영상이 수정되었습니다.'); 리스트 페이지로 이동 후 토스트 팝업 호출
        }
    },
    created() {
        if (this.$route.meta.mode) {
            this.pageMode = this.$route.meta.mode;
            this.modifyHash = this.$route.params.hash;
        }
    },
}
</script>

<style scoped>

</style>